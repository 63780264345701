body {
  font-family: 'Lora';
  background: linear-gradient(rgba(228, 227, 227, 0.65), rgba(255, 255, 255, 0.65)), url("../images/app/country/bg-master-blanco.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  font-family: 'Rubik', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Rubick', sans-serif;
}

p {
  line-height: 1.75;
}

.text-faded {
  color: rgba(255, 255, 255, 0.3);
}

.site-heading {
  margin-top: 5rem;
  margin-bottom: 5rem;
  text-transform: uppercase;
  line-height: 1;
  font-family: 'Raleway';
}

.site-heading .site-heading-upper {
  font-size: 5rem;
  font-weight: 100;
  line-height: 4rem;
  font-family: 'Raleway';
}

.site-heading .site-heading-lower {

  display: block;
  font-size: 1.75rem;
  font-weight: 500;  
  font-family: 'Rubik', sans-serif;
}

.page-section {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

@media (min-width: 992px) {
  .header-section{
    padding-bottom:120px;
  }
}
.section-heading {
  text-transform: auto;
}

.section-heading .section-heading-upper {
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.section-heading .section-heading-lower {
  display: block;
  font-size: 3rem;
  font-weight: 700;
  color:#232323;
}

.bg-faded {
  background-color: rgba(255, 255, 255, 0.85);
}

#mainNav {
  background-color: #ffffffa4;
  /*font-family: 'Raleway';*/
}

#mainNav .navbar-brand {
  color: #767676;
}

#mainNav .navbar-nav .nav-item .nav-link, #mainNav .navbar-nav .dropdown .dropdown-toggle {
  color: rgb(0, 102, 0, 0.7);
  font-weight: 800;
}

#mainNav .navbar-nav .nav-item.active .nav-link {
  color: #767676;
}

@media (min-width: 992px) {
  #mainNav .navbar-nav .nav-item .nav-link {
    font-size: 0.8rem;
  }
  #mainNav .navbar-nav .nav-item .nav-link:hover {
    color: rgb(0, 0, 0);
  }
  #mainNav .navbar-nav .nav-item.active .nav-link:hover {
    color:  rgb(0, 0, 0);
  }
}

.btn-xl {
  font-weight: 700;
  font-size: 0.8rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.intro {
  position: relative;
}

@media (min-width: 992px) {
  .intro .intro-img {
    width: 75%;
    float: right;
  }
  .intro .intro-text {
    left: 0;
    width: 60%;
    margin-top: 3rem;
    position: absolute;
    color: #767676;
  }
  .intro .intro-text .intro-button {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: -2rem;
  }
}

@media (min-width: 1200px) {
  .intro .intro-text {
    width: 45%;
  }
}

.cta {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: rgba(230, 167, 86, 0.9);
}

.cta .cta-inner {
  position: relative;
  padding: 3rem;
  margin: 0.5rem;
  background-color: rgba(255, 255, 255, 0.85);
}

.cta .cta-inner:before {
  border-radius: 0.5rem;
  content: '';
  position: absolute;
  top: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  border: 0.25rem solid rgba(255, 255, 255, 0.85);
}

@media (min-width: 992px) {
  .about-heading .about-heading-img {
    position: relative;
    z-index: 0;
  }
  .about-heading .about-heading-content {
    margin-top: -5rem;
    position: relative;
    z-index: 1;
  }
}

@media (min-width: 992px) {
  .product-item .product-item-title {
    position: relative;
    z-index: 1;
    margin-bottom: -3rem;
  }
  .product-item .product-item-img {
    position: relative;
    z-index: 0;
    max-width: 60vw;
  }
  .product-item .product-item-description {
    position: relative;
    z-index: 1;
    margin-top: -3rem;
    max-width: 50vw;
  }
}

.list-hours {
  font-size: 0.9rem;
}

.list-hours .list-hours-item {
  border-bottom: 1px solid rgba(230, 167, 86, 0.5);
  padding-bottom: .25rem;
  margin-bottom: 1rem;
  font-style: italic;
}

.list-hours .list-hours-item.today {
  font-weight: bold;
  color: #e6a756;
}

@media (min-width: 992px) {
  .list-hours {
    width: 50%;
    font-size: 1.1rem;
  }
}

.address strong {
  font-size: 1.2rem;
}

.footer {
  background-color: rgba(47, 23, 15, 0.9);
}

.text-primary {
  color: #767676 !important;
}

.bg-primary {
  background-color: rgb(0, 102, 0, 0.7)!important;
}

.btn {
  box-shadow: 0px 3px 3px 0px rgba(33, 37, 41, 0.1);
}

.btn-primary {
  background-color: rgb(0, 102, 0, 0.7);;
  border-color: rgb(0, 102, 0, 0.7);;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: rgb(0, 102, 0, 0.7);;
  border-color: rgb(0, 102, 0, 0.7);;
}

.font-weight-light {
  font-weight: 100 !important;
}

