.Footer {}

.nailRight {
    position: fixed;
    width:350px;
    top: 55vh;
    right: -308px;
    cursor:auto;
    z-index:100;
    color: rgb(0, 102, 0, 0.7);
  }
  .nailRight:hover {
    right: 0px;
    -webkit-transition-duration: 1.0s;
    -moz-transition-duration: 1.0s;
    -o-transition-duration: 1.0s;
    transition-duration: 1.0s;
    z-index:100;
  }
  .innerNailRight {
    width:305px;
    height: 195px;
    float:right;  
    border:solid 5px white;
    background-color:rgba(256,256,256,0.85);
    padding:12px;
    
  }



  .nailLeft {
    position: fixed;
    width:50px;
    top: 60vh;
    left: 0px;
    cursor:auto;
    z-index:100;
    
  }
  
  .nailLeft a{
    clear: both;
    font-size: 32px;
  }

  .nailLeft a,  .nailRight a{
    color:rgb(255, 0, 0);
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 600px) {
    .whatsapp {
      display:block;
    }
  }
  @media only screen and (min-width: 601px) {
    .whatsapp {
      display:none;
    }
  }