.Masterbatch {}

.masterBatchHeader {
    background-image: none; z-index: 0; position: relative; background-attachment: scroll; background-size: auto;
}

.masterBatchHeaderJarallaxContainer {
    position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -100;
}

.masterBatchHeaderJarallax {
    background-position: 50% 50%; background-size: 100%; background-repeat: no-repeat; background-image: url('http://plasticountry.com/img/db/country/info_17_master_principal_XL.jpg'); position: fixed; top: 0px; left: 0px; width: 1354px; height: 585.323px; overflow: hidden; pointer-events: none; margin-left: 0px; margin-top: 18.3385px; visibility: visible; transform: translate3d(0px, 83.4px, 0px);
}

.masterBatchOverlay{
    opacity: 0.2; background-color:rgb(35, 35, 35);
}

.beneficiosParallaxContainer{
    position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -100;
}

.beneficiosParallax{
    background-position: 50% 50%; background-size: 100%; background-repeat: no-repeat; background-image: url('http://plasticountry.com/img/db/country/info_17_master_principal_XL.jpg'); position: fixed; top: 0px; left: 0px; width: 1354px; height: 585.323px; overflow: hidden; pointer-events: none; margin-left: 0px; margin-top: 18.3385px; visibility: visible; transform: translate3d(0px, 897.15px, 0px);
}

.mbr-figure-pigmentos{
    width: 180%;
}

.cid-qLKmepcnHa{
    background-image: none; z-index: 0; position: relative; background-attachment: scroll; background-size: auto;
}

.mbr-iconfont{
    color:rgb(85, 180, 212);
}