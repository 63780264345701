body{max-width: 1920px;   font-family: 'Rubik', sans-serif;}
#mainNav .navbar-nav .nav-item .nav-link, #mainNav .navbar-nav .dropdown .dropdown-toggle {
    color: rgb(0, 102, 0, 0.7);
    font-weight: 500;
}


.y-a_menu {
	border-bottom:solid 2px white!important;
	
}
.y-a_menu:hover{
	border-bottom:solid 2px green!important;
	
}

.l-centered_container{height:100%; width:100%; }
.l-centered_element{position:relative;top:50%;text-align:center}
.l-header_container{margin:15px 0px 0px 0px; color:#767676}
h1, h2, h3, h4, h5 {color:#232323}


@media only screen and (max-width: 600px) {
.l-header_container{margin:85px 0px 0px 0px;}
}
.l-header_container_nosotros{ margin:150px 0px 0px 0px;}
.l-header_container_aditivos{ padding:50px 0px 0px 0px; height:550px!important;}

.l-wowslider_index_container{margin:175px 0px 50px 0px;}
@media only screen and (max-width: 600px) {
.l-wowslider_index_container{margin:100px 0px 24px 0px;}
}

.l-header_width{max-width: 1920px!important;}

.l-full_background{
    max-width: 1920px!important;
    /*background-size: 100vw 59vw!important;     */
       
    width: 100vw!important;
    height: 59vw!important;
    background-size: contain;
    background-color: white;
}




.l-text-block_360px{
    width: 360px;
    max-width: 90%;
}

.l-max-widht-media{max-width: 90%; }

.l-ul_justify li{text-align : justify;}

.l-2_col{
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
    
    -webkit-column-gap: 60px; /* Chrome, Safari, Opera */
    -moz-column-gap: 60px; /* Firefox */
    column-gap: 60px;

}


.l-signature_container{
    width:100%;
    padding:15px 0px 15px 0px;
    color:#444;
    border-bottom-color: black;
    min-height:40px;
	
}
.l-columns_signature{
    text-align:center;
    padding:10px 25px 10px 25px;	

}
.l-logo_footer{width:150px;}

.l-logo_empresa{max-height: 250px; min-width: 250px;}


/*new*/


.dropdownHover button{
    padding: 14px 16px!Important;
    color: #006600 !important;
}
.dropdownHover ul li{
    list-style-type: none;
}